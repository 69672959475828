import React from "react";
import { Header, Segment } from "semantic-ui-react";

const EventActivity = () => {
  return (
    <>
      <Header attached='top' content='Recent Activity' />
      <Segment attached>
        <p>Recent Activity</p>
      </Segment>
    </>
  );
};

export default EventActivity;
