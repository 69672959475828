import React from "react";
import { Button, Divider, Form, Header, Segment } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import RadioInput from "../../../app/common/form/RadioInput";
import TextInput from "../../../app/common/form/TextInput";
import TextArea from "../../../app/common/form/TextArea";
import PlacesInput from "../../../app/common/form/PlacesInput";
import SelectInput from "../../../app/common/form/SelectInput";

const interests = [
  { key: "drinks", text: "Drinks", value: "drinks" },
  { key: "culture", text: "Culture", value: "culture" },
  { key: "film", text: "Film", value: "film" },
  { key: "food", text: "Food", value: "food" },
  { key: "music", text: "Music", value: "music" },
  { key: "travel", text: "Travel", value: "travel" }
];

const AboutPage = ({ pristine, submitting, handleSubmit, updateProfile }) => {
  return (
    <Segment>
      <Header dividing size='large' content='About Me' />
      <p>Complete your profile to get the most out of this site</p>
      <Form onSubmit={handleSubmit(updateProfile)}>
        <Form.Group inline>
          <label>Tell us your status: </label>
          <Field
            name='status'
            component={RadioInput}
            type='radio'
            value='single'
            label='Single'
          />
          <Field
            name='status'
            component={RadioInput}
            type='radio'
            value='relationship'
            label='Relationship'
          />
          <Field
            name='status'
            component={RadioInput}
            type='radio'
            value='married'
            label='Married'
          />
        </Form.Group>
        <Divider />
        <Field
          name='about'
          component={TextArea}
          placeholder='About Me'
          label='About Me'
        />
        <Field
          name='interests'
          component={SelectInput}
          options={interests}
          value='interests'
          multiple={true}
          placeholder='Select your interests'
          label='Your interests'
        />
        <Field
          width={8}
          name='occupation'
          type='text'
          component={TextInput}
          placeholder='Occupation'
          label='Occupation'
        />
        <Field
          width={8}
          name='origin'
          options={{ types: ["(regions)"] }}
          component={PlacesInput}
          placeholder='Country of Origin'
          label='Country of Origin'
        />
        <Divider />
        <Button
          disabled={pristine || submitting}
          size='large'
          positive
          content='Update Profile'
        />
      </Form>
    </Segment>
  );
};

export default reduxForm({
  form: "rxUserProfileForm",
  enableReinitialize: true,
  destroyOnUnmount: false
})(AboutPage);
